$theme: (
    'primary': #0D192B,
    'secondary': #DDE8EF,
    'third': #ABC7D9,

    'primaryLight': #68BDF0,
    'primaryDark': #193256,
    'secondaryLight': #193256,
    'secondaryDark': #04598c,
    'thirdLight': #7BB8D1,
    'thirdDark': #04598c,

    'success': #179794,
    'error': #800F13,
    'warning': #F8B459,
    'info': #006ba4,

    'dark': #262835,
    'light': #F1A542,
    'grey': #DBDFE1,
    'greyDark': #BAC1C5,
    'green': #03DAC5,
    'greenDark': #0D5C63,
);

:export {
    @each $key, $value in $theme {
        #{unquote($key)}: $value;
    }
}